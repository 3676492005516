import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/dvJm5liuAA8">
    <SEO title="A Commercialized Christmas - Christmas at the Movies" />
  </Layout>
)

export default SermonPost
